import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NewSampleCardComponent } from '../new-sample-card/new-sample-card.component';
import { NewSample } from '../../models';

@Component({
  standalone: true,
  selector: 'app-new-sample-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgScrollbarModule,
    MatButtonModule,
    FontsModule,
    NewSampleCardComponent,
  ],
  template: `
    <div
      class="samples-list__header flex-space-between pr-8 pl-8"
      *ngIf="samples?.length > 0"
    >
      <small class="mb-8 color-50">
        <strong>{{ samples.length }}</strong> samples
      </small>
      <small>
        <button
          mat-button
          [disabled]="disabled || !canClearAll"
          (click)="deleteAll.emit()"
        >
          <fa-icon icon="trash" size="sm" />
          Clear All
        </button>
      </small>
    </div>
    <ng-scrollbar>
      <app-new-sample-card
        *ngFor="let sample of samples"
        [sample]="sample"
        (delete)="deleteSample.emit(sample)"
      />
    </ng-scrollbar>
  `,
  styles: [
    `
      $margin: 0.5rem;

      .samples-list__header {
        margin-bottom: 0.25rem;
      }

      ng-scrollbar {
        // remove height of header to avoid overflowing in parent container
        max-height: calc(100% - 1.5rem);
      }

      app-new-sample-card {
        display: block;

        &:not(:last-child) {
          margin-bottom: $margin;
        }
      }
    `,
  ],
})
export class NewSampleListComponent {
  @Input()
  set samples(samples: NewSample[]) {
    this._samples = samples;
    this.canClearAll = samples.filter(s => !s.uploaded)?.length > 0;
  }
  get samples(): NewSample[] {
    return this._samples;
  }

  @Input({ transform: booleanAttribute })
  disabled = false;

  @Output()
  deleteSample = new EventEmitter<NewSample>();

  @Output()
  deleteAll = new EventEmitter<void>();

  canClearAll = false;

  private _samples: NewSample[];
}
