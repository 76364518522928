import { Signal, signal } from '@angular/core';
import { RosettaErrorMessage } from '@shared/modules/error-message/error-message.model';
import { TestPackGridSelection } from './test-pack-grid-selection.model';
import { TransientSample } from './transient-sample.model';

export enum SampleState {
  Initial = 'initial',
  Saving = 'saving',
  Saved = 'saved',
  Errored = 'errored',
}

export class NewSample {
  constructor(
    private _name: string,
    private _json: string,
    private _selection: TestPackGridSelection,
    private _fileName?: string
  ) {}

  private _errorReason?: RosettaErrorMessage;
  private _status = signal<SampleState>(SampleState.Initial);

  get status(): Signal<SampleState> {
    return this._status.asReadonly();
  }

  get errorReason(): RosettaErrorMessage | undefined {
    return this._errorReason;
  }

  get name(): string {
    return this._name;
  }

  get fileName(): string | undefined {
    return this._fileName;
  }

  get json(): string | undefined {
    return this._json;
  }

  get uploaded(): boolean {
    return this._status() === SampleState.Saved;
  }

  get waitingForUpload(): boolean {
    return this._status() === SampleState.Initial;
  }

  setError(erroredReason: RosettaErrorMessage): void {
    this._status.set(SampleState.Errored);
    this._errorReason = erroredReason;
  }

  updateStatus(updateStatus: Exclude<SampleState, SampleState.Errored>): void {
    this._status.set(updateStatus as SampleState);
    this._errorReason = null;
  }

  updateName(updatedName: string): void {
    this._name = updatedName;
  }

  updateJson(json: string): void {
    this._json = json;
  }

  getTransientSample(): TransientSample {
    return {
      testPackDef: this._selection.testPackDef,
      sample: {
        sampleDef: {
          id: this.name,
          name: this.name,
        },
        json: this._json,
      },
    };
  }
}
