import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MAX_FILE_UPLOAD_MB, getDialogOptions } from '@configs';
import { DialogComponent } from '@models';
import { InOutAnimation, SlideInAnimation } from '@shared/animations';
import { TestPackGridSelection } from '@shared/modules/transform/models/test-pack-grid-selection.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '@shared/modules/transform/models/transform-config.model';
import { ValidationError, fileTypeSanitiser } from '@utils';
import { Observable, Subject, first, last, skipWhile, switchMap } from 'rxjs';
import { NewSample } from '../../models';
import { TransformSampleStoreService } from '../../services/transform-sample-store.service';

@Component({
  selector: 'app-transform-add-sample-dialog',
  templateUrl: './transform-add-sample-dialog.component.html',
  styleUrls: ['./transform-add-sample-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation, SlideInAnimation],
})
export class TransformAddSampleDialogComponent
  implements DialogComponent, OnInit
{
  constructor(
    private _sampleStoreService: TransformSampleStoreService,
    private _dialogRef: MatDialogRef<TransformAddSampleDialogComponent>,
    private _destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) public selection: TestPackGridSelection,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {}

  readonly maxFileSizeMb = MAX_FILE_UPLOAD_MB.toFixed(2);
  readonly maxSamplesCount = 10;

  pipelineLabel = this._transformConfig.pipelineSelector.label;
  testPackLabel = this._transformConfig.testPackSelector.label;
  fileType = fileTypeSanitiser(
    this.selection.pipelineDef.inputSerialisationFormat
  );

  newSampleList$: Observable<NewSample[]>;
  errorList$: Observable<ValidationError[] | null>;
  isUploading$: Observable<boolean>;
  disableAddSamples$: Observable<boolean>;

  newSamplesUploaded = signal(false);
  storeOnServer = false;

  private _generalError$ = new Subject<string | null>();
  generalError$ = this._generalError$.asObservable();

  static options(data: Partial<TestPackGridSelection>): MatDialogConfig {
    return getDialogOptions('md', {
      disableClose: true,
      autoFocus: false,
      data,
    });
  }

  ngOnInit(): void {
    this.newSampleList$ = this._sampleStoreService.sampleList$;
    this.errorList$ = this._sampleStoreService.errorList$;
    this.isUploading$ = this._sampleStoreService.isUploading$;
    this.disableAddSamples$ = this._sampleStoreService.disableAddSamples$;

    this._dialogRef
      .beforeClosed()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(newSamplesUploaded =>
        this._cleanupOnClose(newSamplesUploaded)
      );

    this._dialogRef
      .backdropClick()
      .pipe(
        switchMap(() => this.isUploading$.pipe(first())),
        skipWhile(isUploading => isUploading),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => this._dialogRef.close(this.newSamplesUploaded()));
  }

  onFileDropped(files: FileList): void {
    this._generalError$.next(null);
    if (this._validateSampleCount(files)) {
      this._sampleStoreService.addSample(files, this.selection).subscribe();
    }
  }

  onDeleteSample(sample: NewSample): void {
    this._sampleStoreService.removeSample(sample);
  }

  onDeleteAllSamples(): void {
    this._sampleStoreService.clear();
  }

  uploadAddedSamples(): void {
    this.newSamplesUploaded.set(true);
    this._sampleStoreService
      .uploadAddedSamples(this.selection, this.storeOnServer)
      .pipe(last())
      .subscribe(allSamplesSuccessfullyUploaded => {
        if (allSamplesSuccessfullyUploaded) {
          this.clearAddedSamples();
          this._dialogRef.close(true);
        }
      });
  }

  clearAddedSamples(): void {
    this.newSamplesUploaded.set(false);
    this._sampleStoreService.clearSuccessfulSamples();
  }

  private _cleanupOnClose(newSamplesUploaded: boolean): void {
    this._sampleStoreService.clearErrorList();
    this._generalError$.next(null);

    if (newSamplesUploaded) {
      this.clearAddedSamples();
    }
  }

  private _validateSampleCount(files: FileList): boolean {
    const success =
      files.length + (this._sampleStoreService.samplesCount ?? 0) <=
      this.maxSamplesCount;

    if (!success) {
      this._generalError$.next(
        `Only ${this.maxSamplesCount} samples can be added at a time.`
      );
    }
    return success;
  }
}
