import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import {
  HoverClassDirective,
  ShowWhenWorkspaceDirective,
} from '@shared/directives';
import { CollapsiblePanelModule } from '@shared/modules/collapsible-panel/collapsible-panel.module';
import { DataStorageWarningModule } from '@shared/modules/data-storage-warning/data-storage-warning.module';
import { DiagnosticPanelModule } from '@shared/modules/diagnostic-panel/diagnostic-panel.module';
import { FilterFieldModule } from '@shared/modules/filter-field/filter-field.module';
import { InlineErrorModule } from '@shared/modules/inline-error/inline-error.module';
import { RosettaSelectorModule } from '@shared/modules/rosetta-selector/rosetta-selector.module';
import {
  EmptyStringPipe,
  GetWorkspaceInfoPipe,
  RosettaTitleCasePipe,
} from '@shared/pipes';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ActionPanelComponent } from '../action-panel/action-panel.component';
import { CodeViewModule } from '../code-view/code-view.module';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { RosettaFileDropComponent } from '../rosetta-file-drop/rosetta-file-drop.component';
import { RosettaFileSelectComponent } from '../rosetta-file-select/rosetta-file-select.component';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { RosettaOverlayModule } from '../rosetta-overlay/rosetta-overlay.modules';
import { RosettaTableComponent } from '../rosetta-table/rosetta-table.component';
import { RosettaTourModule } from '../rosetta-tour/rosetta-tour.module';
import { StopPropagationModule } from '../stop-propagation/stop-propagation.module';
import { fromComponents } from './components';
import { NewSampleListComponent } from './components/new-sample-list/new-sample-list.component';
import { StorageLocationSelectionComponent } from './components/storage-location-selection/storage-location-selection.component';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from './models/transform-config.model';
import { TransformDetailsComponent } from './pages/transform-details/transform-details.component';
import { TransformListComponent } from './pages/transform-list/transform-list.component';
import { IngestApiService } from './services/ingest-api.service';
import { TransformSelectorManagerService } from './services/selectors/transform-selector-manager.service';
import { TransformLocalStorageService } from './services/transform-local-storage.service';
import { TransformServerStorageService } from './services/transform-server-storage.service';
import { TransformStorageService } from './services/transform-storage.service';
import { TransformService } from './services/transform.service';
import * as TransformEffects from './store/transform.effects';
import { TransformRoutingModule } from './transform-routing.module';
import { TransformSampleStoreService } from './services/transform-sample-store.service';

@NgModule({
  declarations: [
    TransformListComponent,
    TransformDetailsComponent,
    ...fromComponents,
  ],
  imports: [
    CommonModule,
    FontsModule,
    TransformRoutingModule,
    FormsModule,

    // UI Modules
    CollapsiblePanelModule,
    DiagnosticPanelModule,
    FilterFieldModule,
    RosettaOverlayModule,
    RosettaSelectorModule,
    DataStorageWarningModule,
    InlineErrorModule,
    RosettaTourModule,
    CodeViewModule,
    LoadingSpinnerModule,
    StopPropagationModule,

    // Third Party
    LetDirective,
    Ng2GoogleChartsModule,
    EffectsModule.forFeature([TransformEffects]),

    // Material
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
    MatTooltipModule,

    // Standalone
    ActionPanelComponent,
    EmptyStringPipe,
    FileUploadComponent,
    GetWorkspaceInfoPipe,
    HoverClassDirective,
    RosettaMenuComponent,
    RosettaTableComponent,
    RosettaTitleCasePipe,
    ShowWhenWorkspaceDirective,
    StorageLocationSelectionComponent,
    NewSampleListComponent,
    RosettaFileDropComponent,
    RosettaFileSelectComponent,
  ],
  providers: [
    IngestApiService,
    RosettaTitleCasePipe,
    TransformLocalStorageService,
    TransformSampleStoreService,
    TransformSelectorManagerService,
    TransformServerStorageService,
    TransformService,
    TransformStorageService,
  ],
})
export class TransformModule {
  static forFeature(
    config: ITransformConfig
  ): ModuleWithProviders<TransformModule> {
    return {
      ngModule: TransformModule,
      providers: [{ provide: TRANSFORM_CONFIG, useValue: config }],
    };
  }
}
