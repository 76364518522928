import { WORKSPACE_READY_TASKS } from '@configs';
import { TaskStatus, TaskStatusMap } from '@models';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as actions from '../actions/task.actions';
import * as WorkspaceActions from '@store/workspace/actions/workspace.actions';

export const featureKey = 'tasks';

export interface State {
  lastStatusChange: number;
  statusMap: TaskStatusMap<TaskStatus>;
  taskMap: TaskStatusMap<any>;
}

export const initialState: State = {
  lastStatusChange: Date.now(),
  statusMap: {},
  taskMap: {},
};

export const reducer = createReducer(
  initialState,
  // The task status map needs to reset in sync with the other store slices when switching workspaces
  on(WorkspaceActions.switchWorkspace, state => ({ ...state, statusMap: {} })),
  on(WorkspaceActions.updateWorkspaceItemContent, state =>
    produce(state, draft => {
      WORKSPACE_READY_TASKS.forEach(
        task => (draft.statusMap[task] = TaskStatus.Pending)
      );
    })
  ),
  on(actions.taskStarted, (state, { name }) =>
    produce(state, draft => {
      // When a new task is started and both statuses are finished we can
      // assume a new task has started and we should reset them to pending
      if (
        WORKSPACE_READY_TASKS.every(
          task => draft.statusMap[task] === TaskStatus.Finished
        )
      ) {
        WORKSPACE_READY_TASKS.forEach(
          task => (draft.statusMap[task] = TaskStatus.Pending)
        );
      }

      draft.statusMap[name] = TaskStatus.Started;
    })
  ),
  on(actions.taskFinished, (state, { name, payload }) =>
    produce(state, draft => {
      if (payload.success || !payload.hasOwnProperty('success')) {
        draft.statusMap[name] = TaskStatus.Finished;
      } else {
        draft.statusMap[name] = TaskStatus.Error;
      }

      draft.lastStatusChange = Date.now();
      draft.taskMap[name] = payload;
    })
  )
);
